import React from "react";
import Dropdown from "../../../components/global/dropdown/Dropdown";
import Header from "../../../components/climax_tracker_global/header/Header";
import './UploadPendingBet.css';
import {get_api_url} from "../../../service/api_service";
import {ClimaxTrackerEndpointsEnum} from "../../../constants/endpoint";

class UploadPendingBetPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            player_1_id: null,
            player_2_id: null,
            climax_amount: 1,
            motive: "",
        }
        this.handle_player_1_update = this.handle_player_1_update.bind(this);
        this.handle_player_2_update = this.handle_player_2_update.bind(this);
        this.handleChangeAmount = this.handleChangeAmount.bind(this);
        this.handleChangeMotive = this.handleChangeMotive.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handle_player_1_update = (profile_id) => {
        this.setState({
                player_1_id: profile_id
            }
        )
    }

    handle_player_2_update = (profile_id) => {
        this.setState({
                player_2_id: profile_id
            }
        )
    }

    handleChangeMotive(event) {
        this.setState({motive: event.target.value});
    }

    handleChangeAmount(event) {
        this.setState({climax_amount: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.uploadBet().then(
            () => alert("Pending Bet uploaded")
        )
    }

    async uploadBet() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                player_1_id: this.state.player_1_id,
                player_2_id: this.state.player_2_id,
                motive: this.state.motive,
                climax_amount: this.state.climax_amount,
            })
        };
        await fetch(get_api_url(ClimaxTrackerEndpointsEnum.UPLOAD_PENDING_BET), requestOptions);
    }

    render() {
        return (
            <>
                <Header/>
                <form onSubmit={this.handleSubmit}>
                    <Dropdown
                        url={ClimaxTrackerEndpointsEnum.PROFILE_LIST}
                        title="Player 1:"
                        handle_profile_update={this.handle_player_1_update}
                    />
                    <Dropdown
                        url={ClimaxTrackerEndpointsEnum.PROFILE_LIST}
                        title="Player 2:"
                        handle_profile_update={this.handle_player_2_update}
                    />
                    <div>
                        <label>
                            Nombre de climax pariés :
                            <input
                                type="number"
                                value={this.state.climax_amount}
                                onChange={this.handleChangeAmount}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            Raison :
                        </label>
                    </div>
                    <div>
                        <textarea value={this.state.motive} onChange={this.handleChangeMotive}/>
                    </div>
                    <input type="submit" value="Submit"/>
                </form>
            </>
        );
    }
}

export default UploadPendingBetPage;
