const base_url = "https://www.louisnicolle-backend.club/"
// const base_url = "http://127.0.0.1:8000/"

export function get_api_url(relative_path, parameters={}){
    const url_without_parameters = base_url + relative_path;
    let is_first_parameter = true;
    let url_with_parameters = url_without_parameters;
    for (const [key, value] of Object.entries(parameters)) {
        if (value.length > 0) {
            if (is_first_parameter) {
                url_with_parameters += "?";
                is_first_parameter = false;
            }
            url_with_parameters += key + "="
            url_with_parameters += value.toString()
        }
    }
    return url_with_parameters
}
