export const AeonEndpointsEnum = {
    CARD_CHARACTERISTIC_DATA: "aeon/card_characteristic_win_rate",
    EFFECTIVE_DAMAGE: "aeon/effective_damage",
    EFFECTIVE_MAXIMUM_DAMAGE: "aeon/effective_maximum_damage",
    ETHER_COST_WIN_RATE: "aeon/average_ether_cost",
    MAGE_POPULARITY: "aeon/mage_popularity",
    NEMESIS_DIFFICULTY_WIN_RATE: "aeon/nemesis_difficulty_win_rate",
    NEMESIS_WIN_RATE: "aeon/nemesis_win_rate",
    UPLOAD_GAME: "admin/aeon/game/add/",
    MAGE_LIST: "aeon/mage",
};

export const ClimaxTrackerEndpointsEnum = {
    PROFILE_LIST: 'climax_tracker/profile',
    PROFILE_DETAIL: 'climax_tracker/profile/',
    FLORENT: 'climax_tracker/florent',
    UPLOAD_BET: 'climax_tracker/bet',
    UPLOAD_PENDING_BET: 'climax_tracker/pending_bet',
    FINISH_PENDING_BET: 'climax_tracker/finish_pending_bet',
}

export const GeneralEndpointsEnum = {
    PROFILE_ACHIEVEMENT: 'achievement/profile/',
    PROFILE_LIST: 'stats/profile',
}
