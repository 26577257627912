import React from "react";
import {Bar, Doughnut, Line, Radar} from "react-chartjs-2";
import {GraphTypeEnum} from "../../../constants/graph_type";

class ChartWrapper extends React.Component {
    // props: url
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            options: {},
            type: null,
            title: null
        }
    }

    componentDidMount() {
        this.updateGraph();
    }

    componentDidUpdate(prevProps) {
        if(this.props.url !== prevProps.url)
        {
            this.updateGraph();
        }
    }

    updateGraph() {
        fetch(this.props.url).then(
            results => results.json()
        ).then(
            api_data => {
                this.setState({
                        data: api_data["data"],
                        options: api_data["options"],
                        type: api_data["type"],
                        title: api_data["title"],
                    }
                )
            }
        )
    }

    chooseComponent() {
        if (this.state.type === GraphTypeEnum.Line) {
            return <Line data={this.state.data} options={this.state.options}/>
        }
        if (this.state.type === GraphTypeEnum.Bar) {
            return <Bar data={this.state.data} options={this.state.options}/>
        }
        if (this.state.type === GraphTypeEnum.Radar) {
            return <Radar data={this.state.data} options={this.state.options}/>
        }
        if (this.state.type === GraphTypeEnum.Doughnut) {
            return <Doughnut data={this.state.data} options={this.state.options}/>
        }
        return <Line data={this.state.data} options={this.state.options}/>
    }


    render() {
        return <>
            <div className='header'>
                <h1 className='title'>{this.state.title}</h1>
            </div>
            <div className={this.state.type}>
                {this.chooseComponent()}
            </div>
        </>
    }
}

export default ChartWrapper;
