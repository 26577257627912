import React from "react";
import Checkbox from "./Checkbox";
import './FilterCheckbox.css'
import {get_api_url} from "../../../service/api_service";

const DEFAULT_OPTIONS = [];

class FilterCheckbox extends React.Component {
    // Props: handle_filters_update & url
    constructor(props) {
        super(props);
        this.state = {
            options: DEFAULT_OPTIONS,
            checkboxes: this.convert_options_to_checkboxes(DEFAULT_OPTIONS),
        }
    }

    convert_options_to_checkboxes(options) {
        return options.reduce(
            (options, option) => ({
                ...options,
                [option]: false
            }),
            {}
        )
    }

    componentDidMount() {
        const url = get_api_url(this.props.url)
        fetch(url).then(
            results => results.json()
        ).then(
            api_data => {
                const mage_names = api_data.map(mage => mage["name"]);
                this.setState({
                        options: mage_names,
                        checkboxes: this.convert_options_to_checkboxes(mage_names)
                    }
                )
            }
        )
    }

    createCheckbox = option => (
        <Checkbox
            label={option}
            isSelected={this.state.checkboxes[option]}
            onCheckboxChange={this.handleCheckboxChange}
            key={option}
        />
    );

    createCheckboxes(options) {
        return options.map(this.createCheckbox);
    }

    handleFormSubmit = formSubmitEvent => {
        formSubmitEvent.preventDefault();

        const filters = Object.keys(this.state.checkboxes)
            .filter(checkbox => this.state.checkboxes[checkbox]);
        this.props.handle_filters_update(filters);
    };

    handleCheckboxChange = changeEvent => {
        const {name} = changeEvent.target;
        this.setState(prevState => ({
                checkboxes: {
                    ...prevState.checkboxes,
                    [name]: !prevState.checkboxes[name]
                }
            })
        )
    }

    render() {
        return <div className="filter-list">
            {this.createCheckboxes(this.state.options)}
            <form onSubmit={this.handleFormSubmit}>
                <div className="form-group">
                    <button type="submit" className="filter-button">
                        Filter
                    </button>
                </div>
            </form>
        </div>
    }
}

export default FilterCheckbox;
