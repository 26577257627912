import React from "react";

class AchievementList extends React.Component {
    /*
    props data: {
        "id":3,
        "name":"Florent Derville",
        "achievements":[
            {
                "id":2,
                "granted_at":"2021-07-09T13:11:58.885720Z",
                "name":"Début de la faim"
            }
        ]
    }
    */

    generate_row(bet_data) {
        return (
            <tr key={bet_data['id']}>
                <td>{bet_data['name']}</td>
            </tr>
        );
    }

    generate_table(achievements) {
        if (achievements) {
            return (
                <table>
                    <thead>
                    <th>Achievements</th>
                    </thead>
                    {achievements.map(this.generate_row)}
                </table>
            )
        }
    }

    render() {
        return (
            <div>
                {this.generate_table(this.props.data['achievements'])}
            </div>
        )
    }
}

export default AchievementList;