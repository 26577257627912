import React from "react";
import "./ClimaxTracker.css";
import Header from "../../../components/climax_tracker_global/header/Header";

class ClimaxTrackerHome extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <div
                    className="climax"
                >
                    <h1 className="title title-climax">Climax Tracker</h1>
                    <div className="bottom-left">
                        <button>
                            Manger
                        </button>
                        <button>
                            <a href="/climax_tracker/upload_pending_bet">
                                Pari en cours
                            </a>
                        </button>
                        <button>
                            <a href="/climax_tracker/upload_bet">
                                Pari fini
                            </a>
                        </button>
                    </div>
                    <div className="bottom-right">
                        <button>
                            <a href="/climax_tracker/profile">
                                Profile
                            </a>
                        </button>
                        <button>
                            <a href="/climax_tracker/florent">
                                Florent
                            </a>
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default ClimaxTrackerHome;
