import AeonHome from "../AeonHome";
import './SingleGraph.css';
import {change_page} from "../../../service/page_navigation";
import React from "react";
import ChartWrapper from "../../../components/global/chart_wrapper/ChartWrapper";
import Header from "../../../components/aeon_global/header/Header";
import FilterCheckbox from "../../../components/global/filter/FilterCheckbox";
import {AeonEndpointsEnum} from "../../../constants/endpoint";
import {get_api_url} from "../../../service/api_service";

class SingleGraphPage extends React.Component {
    //props: url (graph url) & should_display_mage_filter
    constructor(props) {
        super(props);
        this.state = {
            url: get_api_url(props.url),
            filters: {}
        }
        this.handle_filters_update = this.handle_filters_update.bind(this)
    }

    handle_filters_update = (mages) => {
        this.setState({
                filters: {
                    'mage': mages
                }
            },
            this.update_graph
        )
    }

    update_graph() {
        const url = get_api_url(this.props.url, this.state["filters"])
        this.setState(
            {
                url: url
            }
        )
    }

    create_filters() {
        if (this.props.should_display_mage_filter) {
            return <FilterCheckbox
                handle_filters_update={this.handle_filters_update}
                url={AeonEndpointsEnum.MAGE_LIST}
            />
        } else {
            return null
        }
    }

    render() {
        return (
            <>
                <Header/>
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-1">
                                {this.create_filters()}
                            </div>
                            <div className="col-8">
                                <ChartWrapper
                                    url={this.state.url}
                                />
                            </div>
                        </div>
                    </div>
                    <button onClick={() => change_page(<AeonHome/>)}>
                        Home
                    </button>
                </div>
            </>
        );
    }
}

export default SingleGraphPage;
