import React from "react";
import './ProfileSummary.css';
import ResolvePendingBetButton from "../ResolvePendingBetButton/ResolvePendingBetButton";

class ProfileSummary extends React.Component {
    /*
    props data: {
        "id": id,
        "name": name,
        "climax_eaten": climax_eaten,
        "total_climax_bet_lost": total_climax_bet_lost,
        "total_climax_bet_win": total_climax_bet_win,
        "climax_account": climax_account,
        "unpaid_bets": [
            {
              "id": id,
              "winner_name": name,
              "loser_name": name,
              "climax_amount": climax_amount,
              "motive": motive
            }
          ],
          "won_bets": [
            {
              "id": id,
              "winner_name": name,
              "loser_name": name,
              "climax_amount": climax_amount,
              "motive": motive
            }
          ],
          "pending_bets": [
              {
                "climax_amount": 1
                "id": 1
                "motive": ""
                "player_1_name": "Admin Pro"
                "player_2_name": "Florent Derville"
              },
          ]
    }
    */

    generate_bet_row(bet_data, display_winner = true) {
        const name = display_winner ? bet_data['winner_name'] : bet_data['loser_name']
        return (
            <tr key={bet_data['id']}>
                <td>{name}</td>
                <td>{bet_data['climax_amount']}</td>
                <td>{bet_data['motive']}</td>
            </tr>
        );
    }

    generate_bet_table(bets_data, display_winner = true) {
        if (bets_data) {
            let lines = []
            bets_data.forEach(
                (bet_data) => {
                    lines.push(this.generate_bet_row(bet_data, display_winner))
                }
            )

            const other_player_role = display_winner ? 'Gagnant' : 'Perdant'

            return (
                <table>
                    <thead>
                    <th>{other_player_role}</th>
                    <th>Nombre de Climax</th>
                    <th>Raison</th>
                    </thead>
                    {lines}
                </table>
            )
        }
    }

    generate_pending_bet_row(pending_bet_data) {
        const name = (pending_bet_data["player_1_name"] === this.props.data["name"]) ?
            pending_bet_data["player_2_name"] :
            pending_bet_data["player_1_name"];
        return (
            <tr key={pending_bet_data['id']}>
                <td>{name}</td>
                <td>{pending_bet_data['climax_amount']}</td>
                <td>{pending_bet_data['motive']}</td>
                <td>
                    <
                        ResolvePendingBetButton
                        bet_id={pending_bet_data['id']}
                        player_id={this.props.data["id"]}
                        is_win={true}
                    />
                </td>
                <td>
                    <
                        ResolvePendingBetButton
                        bet_id={pending_bet_data['id']}
                        player_id={this.props.data["id"]}
                        is_win={false}
                    />
                </td>
            </tr>
        );
    }

    generate_pending_bet_table(pendings_bet_data) {
        if (pendings_bet_data) {
            let lines = []
            pendings_bet_data.forEach(
                (bet_data) => {
                    lines.push(this.generate_pending_bet_row(bet_data))
                }
            )

            return (
                <table>
                    <thead>
                    <th>Adversaire</th>
                    <th>Nombre de Climax</th>
                    <th>Raison</th>
                    <th></th>
                    <th></th>
                    </thead>
                    {lines}
                </table>
            )
        }
    }

    render() {
        return (
            <div className="profile-summary">
                <p>Name: {this.props.data["name"]}</p>
                <p>Dette actuelle: {this.props.data["climax_account"]}</p>
                <p>Nombre total de climax mangé: {this.props.data["climax_eaten"]}</p>
                <p>Nombre total de climax gagnés en paris: {this.props.data["total_climax_bet_win"]}</p>
                <p>Nombre total de climax perdus en paris: {this.props.data["total_climax_bet_lost"]}</p>
                <p className="bold">Dettes à rembourser: </p>
                {this.generate_bet_table(this.props.data['unpaid_bets'], true)}
                <p className="bold">Paris en cours: </p>
                {this.generate_pending_bet_table(this.props.data['pending_bets'], true)}
                <p className="bold">Paris gagné: </p>
                {this.generate_bet_table(this.props.data['won_bets'], false)}
            </div>
        )
    }
}

export default ProfileSummary;