import React from "react";
import Dropdown from "../../components/global/dropdown/Dropdown";
import {get_api_url} from "../../service/api_service";
import ProfileSummary from "../../components/page/climax_profile/ProfileSummary/ProfileSummary";
import {ClimaxTrackerEndpointsEnum} from "../../constants/endpoint";
import axios from "axios";
import Header from "../../components/climax_tracker_global/header/Header";

class ClimaxTrackerProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile_id: null,
            profile_data: {},
        }
        this.handle_profile_update = this.handle_profile_update.bind(this)
    }

    handle_profile_update = (profile_id) => {
        this.setState({
                profile_id: profile_id
            },
            this.update_data
        )
    }

    async update_data() {
        const url = get_api_url(
            ClimaxTrackerEndpointsEnum.PROFILE_DETAIL + this.state.profile_id
        )
        const response = await axios.get(url)
        const data = response.data
        this.setState({profile_data: data})
    }

    render() {
        return (
            <>
                <Header/>
                <Dropdown
                    url={ClimaxTrackerEndpointsEnum.PROFILE_LIST}
                    title="Choose Profile:"
                    handle_profile_update={this.handle_profile_update}
                />
                <ProfileSummary
                    data={this.state.profile_data}
                />
            </>
        );
    }
}

export default ClimaxTrackerProfile;
