import React from "react";

class Checkbox extends React.Component {
    // props: label, key, isSelected, onCheckboxChange
    render() {
        return <div className="form-check">
            <label>
                <input
                    type="checkbox"
                    name={this.props.label}
                    checked={this.props.isSelected}
                    onChange={this.props.onCheckboxChange}
                    className="form-check-input"
                />
                {this.props.label}
            </label>
        </div>
    }
}

export default Checkbox;
