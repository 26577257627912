import React from "react";
import ButtonList from "../../components/page/aeon_home/ButtonList";
import Header from "../../components/aeon_global/header/Header";

class AeonHome extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <div className="content">
                    <h1 className="title">Aeon's End</h1>
                    <ButtonList/>
                </div>
            </>
        );
    }
}

export default AeonHome;
