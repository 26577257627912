import React from "react";
import "./ResolvePendingBetButton.css"
import {get_api_url} from "../../../../service/api_service";
import {ClimaxTrackerEndpointsEnum} from "../../../../constants/endpoint";

class ResolvePendingBetButton extends React.Component {
    // props: bet_id, player_id, is_win
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    async uploadBet() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                pending_bet_id: this.props.bet_id,
                player_id: this.props.player_id,
                is_victory: this.props.is_win,
            })
        };
        await fetch(get_api_url(ClimaxTrackerEndpointsEnum.FINISH_PENDING_BET), requestOptions);
    }

    handleClick() {
        this.uploadBet().then(
            () => alert("Result Uploaded")
        )
    }

    render() {

        const className = this.props.is_win ? "background-green": "background-red";
        const title = this.props.is_win ? "Gagné": "Perdu";

        return (
            <button onClick={this.handleClick} className={className}>{title}</button>
        )
    }
}

export default ResolvePendingBetButton;