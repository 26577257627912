import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AeonHome from "./page/aeon/AeonHome";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import ClimaxTrackerHome from "./page/climax_tracker/Home/ClimaxTrackerHome";
import ClimaxTrackerProfile from "./page/climax_tracker/ClimaxProfile";
import ClimaxTrackerFlorent from "./page/climax_tracker/ClimaxFlorent";
import UploadBetPage from "./page/climax_tracker/UploadBet/UploadBet";
import ProfileAchievement from "./page/general/Achievement";
import UploadPendingBetPage from "./page/climax_tracker/UploadPendingBet/UploadPendingBet";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/aeon" component={AeonHome} />
            <Route exact path="/" component={ProfileAchievement} />
            <Route exact path="/climax_tracker" component={ClimaxTrackerHome}/>
            <Route path="/climax_tracker/florent" component={ClimaxTrackerFlorent}/>
            <Route path="/climax_tracker/profile" component={ClimaxTrackerProfile}/>
            <Route path="/climax_tracker/upload_bet" component={UploadBetPage}/>
            <Route path="/climax_tracker/upload_pending_bet" component={UploadPendingBetPage}/>
        </Switch>
    </BrowserRouter>,
    document.getElementById('app')
);
