import React from "react";
import './Header.css'
import {AiFillHome, AiOutlinePlusCircle} from "react-icons/all";
import {AeonEndpointsEnum} from "../../../constants/endpoint";
import {get_api_url} from "../../../service/api_service";


class Header extends React.Component {
    render() {
        return (
            <header className="header-basic">

                <div className="header-limiter">

                    <h1><span><AiFillHome/></span></h1>

                    <nav>
                        <span><AiOutlinePlusCircle/>  <a href={get_api_url(AeonEndpointsEnum.UPLOAD_GAME)}>Add a Game</a></span>
                        <span>Contact</span>
                    </nav>
                </div>

            </header>
        );
    }
}

export default Header;
