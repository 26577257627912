import React from "react";
import GraphButton from "./GraphButton";
import {AeonEndpointsEnum} from "../../../constants/endpoint";
import "./ButtonList.css"

class ButtonList extends React.Component {
    render() {
        return (
            <div className="button-list">
                <h3 className="graph-title">All Graphs</h3>
                <div>
                    <GraphButton
                        url={AeonEndpointsEnum.CARD_CHARACTERISTIC_DATA}
                        title="Cards Characteristics"
                        should_display_mage_filter={false}
                    />
                    <GraphButton
                        url={AeonEndpointsEnum.EFFECTIVE_DAMAGE}
                        title="Effective Damage"
                        should_display_mage_filter={true}
                    />
                </div>
                <div>
                    <GraphButton
                        url={AeonEndpointsEnum.EFFECTIVE_MAXIMUM_DAMAGE}
                        title="Effective Maximum Damage"
                        should_display_mage_filter={true}
                    />
                    <GraphButton
                        url={AeonEndpointsEnum.ETHER_COST_WIN_RATE}
                        title="Ether Cost Win-Rate"
                        should_display_mage_filter={true}
                    />
                </div>
                <div>
                    <GraphButton
                        url={AeonEndpointsEnum.MAGE_POPULARITY}
                        title="Mage Popularity"
                        should_display_mage_filter={false}
                    />
                    <GraphButton
                        url={AeonEndpointsEnum.NEMESIS_DIFFICULTY_WIN_RATE}
                        title="Nemesis Difficulty Win-Rate"
                        should_display_mage_filter={true}
                    />
                </div>
                <div>
                    <GraphButton
                        url={AeonEndpointsEnum.NEMESIS_WIN_RATE}
                        title="Nemesis Win-Rate"
                        should_display_mage_filter={true}
                    />
                </div>
            </div>
        );
    }
}

export default ButtonList;
