import React from "react";
import Dropdown from "../../components/global/dropdown/Dropdown";
import {get_api_url} from "../../service/api_service";
import axios from "axios";
import {GeneralEndpointsEnum} from "../../constants/endpoint";
import AchievementList from "../../components/page/general/AchievementList";

class ProfileAchievement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile_id: null,
            profile_data: {},
        }
        this.handle_profile_update = this.handle_profile_update.bind(this)
    }

    handle_profile_update = (profile_id) => {
        this.setState({
                profile_id: profile_id
            },
            this.update_data
        )
    }

    async update_data() {
        const url = get_api_url(
            GeneralEndpointsEnum.PROFILE_ACHIEVEMENT + this.state.profile_id
        )
        const response = await axios.get(url)
        const data = response.data
        this.setState({profile_data: data})
    }

    render() {
        return (
            <>
                <Dropdown
                    url={GeneralEndpointsEnum.PROFILE_LIST}
                    title="Choose Profile:"
                    handle_profile_update={this.handle_profile_update}
                />
                <AchievementList
                    data={this.state.profile_data}
                />
            </>
        );
    }
}

export default ProfileAchievement;
