import React from "react";
import './Header.css'
import {AiFillHome} from "react-icons/all";


class Header extends React.Component {
    render() {
        return (
            <header className="header-basic">

                <div className="header-limiter">

                    <h1><span><a href='/climax_tracker'><AiFillHome/></a></span></h1>

                    <nav>
                        <span>Contact</span>
                    </nav>
                </div>

            </header>
        );
    }
}

export default Header;
