import React from "react";
import {get_api_url} from "../../service/api_service";
import {ClimaxTrackerEndpointsEnum} from "../../constants/endpoint";
import axios from "axios";
import FlorentData from "../../components/page/climax_profile/FlorentData";
import Header from "../../components/climax_tracker_global/header/Header";

class ClimaxTrackerFlorent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            florent_data: {},
        }
    }

    componentDidMount() {
        this.update_data()
    }

    async update_data() {
        const url = get_api_url(
            ClimaxTrackerEndpointsEnum.FLORENT
        )
        const response = await axios.get(url)
        const data = response.data
        this.setState({florent_data: data})
    }

    render() {
        return (
            <>
                <Header/>
                <h1 className="title">Florent</h1>
                <FlorentData data={this.state.florent_data}/>
            </>
        )
    }
}

export default ClimaxTrackerFlorent;