import React from "react";
import './FlorentData.css';

class FlorentData extends React.Component {
    /*
    props data: {
          "total_climax_eaten": 5,
          "total_debt": 2188,
          "remaining_climax": 2183,
          "remaining_percentage": 100,
          "climax_frequency": {
            "seconds": 864790,
            "minutes": 14413,
            "hours": 240,
            "days": 10,
            "years": 0
          },
          "theoretical_number_of_climax_eaten": 0
        }
    */

    generate_current_debt() {
        const current_climax_account = this.props.data["total_climax_eaten"] - this.props.data["theoretical_number_of_climax_eaten"]
        if (current_climax_account > 0) {
            return (<p className="green bold florent-focus">
                Actuellement en avance de: {current_climax_account} climax
            </p>)
        }
        if (current_climax_account === 0) {
            return (<p className="green bold florent-focus">
                Exactement en rythme
            </p>)
        }
        if (current_climax_account < 0) {
            return (<p className="red bold florent-focus">
                Actuellement en retard de: {current_climax_account} climax
            </p>)
        }
    }

    generate_frequency() {
        if (this.props.data["climax_frequency"]) {
            return (
                <>
                    <p>Fréquence nécessaire pour tenir la dette</p>
                    <ul>
                        <li className='bold'>{this.props.data["climax_frequency"]["days"]} jours/climax</li>
                        <li>{this.props.data["climax_frequency"]["hours"]} heures/climax</li>
                        <li>{this.props.data["climax_frequency"]["minutes"]} minutes/climax</li>
                        <li>{this.props.data["climax_frequency"]["seconds"]} secondes/climax</li>
                    </ul>
                </>
            )
        }
    }

    render() {
        return (
            <div className="florent-data">
                {this.generate_current_debt()}
                <p>Dette totale: {this.props.data["total_debt"]}</p>
                <p>Nombre total de climax mangé: {this.props.data["total_climax_eaten"]}</p>
                <p>Climax Restants: {this.props.data["remaining_climax"]}</p>
                <p>Pourcentage Restant: {this.props.data["remaining_percentage"]}%</p>
                {this.generate_frequency()}
            </div>
        )
    }
}

export default FlorentData;