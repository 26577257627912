import React from "react";
import {change_page} from "../../../service/page_navigation";
import SingleGraphPage from "../../../page/aeon/single_graph/SingleGraph";

class GraphButton extends React.Component {
    // props: url, title, should_display_mage_filter

    render() {
        return (
            <button onClick={() => change_page(<SingleGraphPage
                url={this.props.url}
                should_display_mage_filter={this.props.should_display_mage_filter}
            />)}>
                {this.props.title}
            </button>
        );
    }
}

export default GraphButton;
