import React from "react";
import Select from 'react-select'
import axios from "axios";
import {get_api_url} from "../../../service/api_service";
import './Dropdown.css';

class Dropdown extends React.Component {
    // Props: title & handle_profile_update & url
    constructor(props) {
        super(props)
        this.state = {
            selectOptions: [],
            id: "",
            name: ''
        }
    }

    async getOptions() {
        const res = await axios.get(get_api_url(this.props.url))
        const data = res.data

        const options = data.map(option => ({
            "value": option.id,
            "label": option.name
        }))
        this.setState({selectOptions: options})
    }

    componentDidMount() {
        this.getOptions()
    }

    handleChange(element) {
        this.setState({
                id: element.value,
                name: element.label
            })
        this.props.handle_profile_update(element.value);
    }

    render() {
        return (
            <div>
                <div className="dropdown">
                <h2>{this.props.title}</h2>
                <Select
                    options={this.state.selectOptions}
                    onChange={this.handleChange.bind(this)}
                />
                </div>
            </div>
        );
    }
}

export default Dropdown;
